<template>
  <div>
    <div class="service-and-toggle">
      <div class="rounded datepicker">
        <md-chip class="rounded">
          <md-datepicker
            v-if="!realTime"
            name="datepicker"
            class="white"
            v-model="selectedDate"
            :md-disabled-dates="disabledDates"
            md-immediately
          />
          <span v-else class="disabled rounded bg-grey">
            DatePicker Disabled</span
          >
        </md-chip>
      </div>
      <div class="switch-div rounded">
        <md-switch class="switch rounded" v-model="autoScroll"
          ><span class="fullText">Auto Scroll</span
          ><span class="shortText">AS</span></md-switch
        >
        <md-switch class="switch rounded" v-model="realTime"
          ><span class="fullText">Real Time</span
          ><span class="shortText">RT</span></md-switch
        >
      </div>
    </div>
    <div class="logArea rounded bg-color" @scroll="handelScroll">
      <span
        class="log rounded"
        v-for="(log, key) in LogsDataArr"
        :key="log + key"
      >
        <span v-bind:class="{ [`${log.color}`]: true }" class="rounded">{{
          log.timestamp
        }}</span>
        {{ log.message }}
        <span v-if="log.detail"> {{ JSON.parse(log.detail) }}</span>
      </span>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LogArea',
    data() {
      return {
        autoScroll: true,
        tempDateHolder: new Date(),
        selectedDate: new Date(),
        disabledDates: (date) => {
          return date.getTime() > new Date().getTime()
        },
      }
    },
    computed: {
      LogsDataArr() {
        return this.$store.state['logs']
      },
      realTime: {
        get() {
          return this.$store.state.realTime
        },
        set() {
          this.toggleIt()
        },
      },
      services: function() {
        return this.$store.state.serviceNames
      },
    },
    updated() {
      this.scrollToBottom(this.autoScroll)
      if (this.LogsDataArr.length <= 200) {
        setTimeout(this.scrollToBottom, 0, true)
      }
    },
    watch: {
      selectedDate: function(val) {
        //do something when the data changes.
        if (this.tempDateHolder.getTime() !== val.getTime()) {
          this.tempDateHolder = val
          this.$store.dispatch('getLogs', { dateSelected: val })
        }
      },
    },
    methods: {
      toggleIt() {
        this.autoScroll = !this.realTime
        this.$store.dispatch('updateStateDirect', {
          fieldName: 'realTime',
          fieldValue: !this.realTime,
        })
        if (this.realTime) {
          this.$store.dispatch('getAllServices')
        }
        this.$store.dispatch('getLogs', {})
      },
      scrollToBottom(autoScroll) {
        if (autoScroll) {
          let logs = document.getElementsByClassName('logArea')[0]
          logs.lastElementChild.scrollIntoView()
        }
      },
      handelScroll() {
        if (!this.realTime) {
          let logArea = document.getElementsByClassName('logArea')[0]
          let oldHeight = logArea.scrollHeight
          if (logArea.scrollTop == 0) {
            let logs = this.$store.state.tempLogsHolder
            if (logs.length >= 200) {
              this.$store.commit('moreLogs', logs.slice(logs.length - 200))
              this.$store.commit(
                'tempLogsHolder',
                logs.slice(0, logs.length - 200)
              )
            } else if (logs.length > 0 && logs.length < 200) {
              this.$store.commit('moreLogs', logs.slice(0))
              this.$store.commit('tempLogsHolder', [])
            }
            setTimeout(() => {
              logArea.scrollTop =
                logArea.scrollHeight - oldHeight - logArea.clientHeight
            }, 0)
          }
        }
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .logArea {
    display: block;
    margin: auto;
    height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .log {
    text-align: left;
    margin: 7px;
    display: flow-root;
  }

  .log-button {
    display: -webkit-inline-box;
  }

  .switch {
    padding: 5px;
    margin: 0;
    margin-left: 3.5px;
    margin-right: 3.5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .switch-div {
    width: 50%;
    margin: 2px;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.12);
  }

  .service-and-toggle {
    display: flex;
    margin-bottom: 9px;
  }

  select {
    width: 80%;
    outline: none;
    border: hidden;
    background-color: rgba(255, 255, 255, 0);
    color: white;
  }

  .md-switch-label {
    padding-left: 7px !important;
  }

  @media screen and (max-height: 823px) {
    .logArea {
      height: 670px;
    }
  }

  @media screen and (max-height: 736px) {
    .logArea {
      height: 570px;
    }
  }

  @media screen and (max-height: 667px) {
    .logArea {
      height: 500px;
    }
  }

  @media screen and (max-height: 568px) {
    .logArea {
      height: 470px;
    }
  }

  @media screen and (max-width: 360px) {
    .switch {
      margin-left: 1px;
    }
  }

  @media screen and (min-width: 801px) {
    .fullText {
      visibility: visible;
    }
    .shortText {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    .fullText {
      display: none;
    }
    .shortText {
      visibility: visible;
    }
    .datepicker > .md-chip {
      width: 98%;
    }
  }
</style>
