import Vue from 'vue'
import App from './App.vue'
// import "./registerServiceWorker";
import router from './router'
import store from './store'
Vue.config.productionTip = false

import {
  MdChips,
  MdProgress,
  MdDatepicker,
  MdButton,
  MdDialog,
  MdSwitch,
} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default-dark.css'
Vue.use(MdChips)
Vue.use(MdProgress)
Vue.use(MdDatepicker)
Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdSwitch)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
