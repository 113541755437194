<template>
  <div id="app">
    <Spinkit v-if="authenticated === null" />
    <template v-else-if="authenticated">
      <div class="nav flex--center bg-color ">
        <select
          class=" header rounded"
          v-model="selectedService"
          @change="switchService"
        >
          <option
            v-for="(service, index) of servicesConfig"
            :key="index"
            :value="service.route"
            >{{ service.text }}</option
          >
        </select>
      </div>
      <router-view />
    </template>
    <div class="red notAutherized" v-else>
      You're not autherized to view logs! Redirect in 2 seconds!
    </div>
  </div>
</template>

<style>
  @import 'assets/styles/global.css';
  .nav {
    padding: 4px 0;
    text-align: center;
  }
  .notAutherized {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    text-align: center;
  }
  .header {
    background: #ffffff26;
    margin: auto;
    display: inline-flex;
    font-size: 20px;
    padding: 5px 20px;
  }

  .progressbar {
    height: 3px;
    display: block;
    position: static;
  }
</style>

<script>
  import Spinkit from './components/Spinkit'
  import servicesConfig from './configs/servicesConfig'
  import allowedRoles from './configs/allowedRoles'
  import httpRequest from './services/httpRequest'
  import { authService, logInUrl } from './configs/apiConfig'
  export default {
    name: 'App',
    components: { Spinkit },
    data: () => {
      return {
        servicesConfig,
        selectedService: null,
        authenticated: null,
      }
    },
    async mounted() {
      // this.selectedService = this.$route.path
      try {
        let res = await httpRequest(authService, {
          method: 'Post',
          mode: 'cors',
          credentials: 'include',
        })
        if (res.status === 200) {
          res = await res.json()
          if (allowedRoles[res.role]) {
            this.authenticated = true
            this.selectedService = this.$route.path
          } else {
            this.authenticated = false
            setTimeout(() => (location.href = logInUrl), 2100)
          }
        } else {
          location.href = `${logInUrl}?redirect=${encodeURI(location.href)}`
        }
      } catch (err) {}
    },
    methods: {
      switchService() {
        this.$router.push(this.selectedService).catch((err) => {})
      },
    },
  }
</script>
