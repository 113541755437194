<template>
  <div>
    <!-- <BackToMainMenu style="position:relative" class="position-center" /> -->
    <div class="progressbar">
      <md-progress-bar
        v-if="progress"
        md-mode="indeterminate"
      ></md-progress-bar>
    </div>
    <div class="home">
      <div class="blocks" style="display: flex;">
        <div class="rounded logTypes">
          <md-chip class="rounded">
            <div
              class="rounded toggle"
              v-for="(logType, key) in logTypes"
              v-bind:class="{
                [logColor[logType.toLowerCase()]]:
                  selectedLogType.toLowerCase() == logType.toLowerCase(),
              }"
              :key="logType + key"
              md-clickable
              @click="selectLogType(logType)"
            >
              {{ logType }}
            </div>
          </md-chip>
        </div>
        <div class="services flex--center rounded bg-grey">
          <select
            :value="serviceSelected"
            name="service"
            id="service"
            @change="selectService($event.target.value)"
          >
            <option selected disabled value="">Services</option>
            <option
              v-for="(service, key) in services"
              :key="service + key"
              :value="service"
            >
              {{ service }}
            </option>
          </select>
        </div>
      </div>
      <LogArea class="blocks" />
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import LogArea from '@/components/LogArea.vue'
  // import BackToMainMenu from '@/components/BackToMainMenu.vue'

  export default {
    name: 'Logs',
    components: {
      LogArea,
      // BackToMainMenu,
    },
    beforeCreate: function() {
      this.$store.commit('makeSocketConnection')
      this.$store.dispatch('socketData')
    },
    data() {
      return {
        logColor: {
          error: 'bg-red',
          info: 'bg-blue',
          warn: 'bg-yellow',
          all: 'bg-white',
        },
      }
    },
    computed: {
      progress() {
        return this.$store.state.progress
      },
      logTypes: function() {
        return this.$store.state.logTypes
      },
      services: function() {
        return this.$store.state.serviceNames
      },
      selectedLogType: function() {
        return this.$store.state.selectedLogType
      },
      serviceSelected: {
        get() {
          return this.$store.state.serviceSelected
        },
        set() {
          this.selectService()
        },
      },
    },
    updated() {
      let element = document.getElementById('service')
      if (!this.services.includes(this.serviceSelected)) {
        element.selectedIndex = 0
      } else {
        element.selectedIndex = this.services.indexOf(this.serviceSelected) + 1
      }
    },
    methods: {
      selectService(service) {
        this.$store.dispatch('getLogs', { service })
      },
      selectLogType(logType) {
        if (!this.serviceSelected)
          this.$store.dispatch('updateStateDirect', {
            fieldName: 'selectedLogType',
            fieldValue: logType,
          })
        else this.$store.dispatch('getLogs', { logType })
      },
    },
  }
</script>

<style>
  .home {
    max-width: 1080px;
    margin: auto;
    justify-content: center;
  }

  .blocks {
    margin: 7px;
    justify-content: center;
    text-align: center;
    min-height: 32px;
  }

  .datepicker > .md-chip {
    width: 99%;
    padding: 0px;
  }

  .datepicker > .md-chip > .md-field {
    width: 100%;
    display: inline-flex;
    position: relative;
    min-height: 0px;
    padding: 0px;
    margin: 0px;
    padding-left: 5px;
  }

  .datepicker > .md-chip > .md-field > input {
    width: 100%;
    margin: 0px;
    display: initial;
    text-align: center;
  }

  .datepicker > .md-chip > .md-field:after {
    height: 0px;
  }

  .datepicker > .md-chip > .md-field > .md-icon:after {
    position: absolute;
    left: -1px;
    bottom: -5px;
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    content: '';
    height: 0px;
  }

  .logTypes > .md-chip.md-clickable > .md-ripple {
    padding: 0 10px;
  }

  .logTypes > .md-chip {
    padding: 0px !important;
    display: flex;
  }

  .toggle {
    width: 33.3%;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .logTypes {
    width: 50%;
  }

  .datepicker {
    width: 50%;
  }

  .disabled {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 0px;
  }

  .services {
    width: 50%;
    margin: 2px;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
  }

  .service-and-toggle {
    display: flex;
    margin-bottom: 7px;
  }

  select {
    outline: none;
    border: hidden;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    text-align-last: center;
  }
  select option {
    color: rgb(31, 33, 35);
  }
  /* .flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  /* .flex-center- {
    display: flex;
    align-items: center;
  } */
  .flex--center {
    display: flex;
    justify-content: center;
  }
  .position-center {
    left: 50%;
    transform: translateX(-50%);
  }
</style>
