const loggerBackend = 'https://logs-backend.access.pub/',
  // const loggerBackend = 'http://localhost:3000/',
  slackApi = {
    url: 'https://slack.com/api',
    endpoints: {
      getMSGs: '/conversations.history',
      getChannels: '/conversations.list',
      deleteMSGs: '/chat.delete',
    },
    token:
      'xoxp-223148668368-936698887766-1660152739638-bad7bd5bcb93e4cffb1bd05ed799d82b',
  },
  authService = 'https://auth.access.pub/authenticate',
  logInUrl = 'https://accounts.access.pub'
export { loggerBackend, slackApi, authService, logInUrl }
