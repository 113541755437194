import Vue from 'vue'
import Vuex from 'vuex'
import { loggerBackend } from '../configs/apiConfig'
import * as io from 'socket.io-client'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socket: null,
    logs: [],
    tempLogsHolder: [],
    progress: true,
    realTime: true,
    serviceNames: [],
    logTypes: ['Error', 'Warn', 'Info'],
    serviceSelected: '',
    selectedLogType: 'Error',
    dateSelected: undefined,
    eventKey: undefined,
    resMsg: '',
  },
  mutations: {
    makeSocketConnection(state) {
      state.socket = io(loggerBackend, {
        path: '',
      })
    },
    stateDirectUpdate(state, data) {
      state[data.fieldName] = data.fieldValue
    },
    newLogs(state, logs) {
      state.logs = state.logs.concat(logs)
    },
    tempLogsHolder(state, logs) {
      state.tempLogsHolder = logs
    },
    moreLogs(state, logs) {
      state.logs = logs.concat(state.logs)
    },
    serviceNames(state, names) {
      state.serviceNames = names
    },
    changeProgress(state, boolean) {
      state.progress = boolean
    },
  },
  actions: {
    updateStateDirect({ commit }, data) {
      commit('stateDirectUpdate', data)
    },
    getLogs({ commit, dispatch, state }, data) {
      dispatch('resetLogs')

      state.socket.off(state.eventKey)

      if (data.service) {
        dispatch('updateStateDirect', {
          fieldName: 'serviceSelected',
          fieldValue: data.service,
        })
      }

      if (data.logType) {
        dispatch('updateStateDirect', {
          fieldName: 'selectedLogType',
          fieldValue: data.logType,
        })
      }

      if (data.dateSelected) {
        dispatch('updateStateDirect', {
          fieldName: 'dateSelected',
          fieldValue: data.dateSelected,
        })
      }

      let service = state.serviceSelected
        ? state.serviceSelected
            .split(' ')
            .join('-')
            .toLowerCase()
        : undefined
      let logType = state.selectedLogType.toLowerCase()
      dispatch('updateStateDirect', {
        fieldName: 'eventKey',
        fieldValue: `${service}-${logType}`,
      })
      let realTime = state.realTime
      let dateSelected = realTime ? new Date() : state.dateSelected

      let reqData = {
        service,
        logType,
        dateSelected,
        realTime,
      }
      state.socket.emit('getAllLogs', reqData)
      commit('changeProgress', true)
      if (state.realTime) {
        state.socket.on(state.eventKey, (log) => {
          commit('newLogs', [log])
        })
      }
    },
    getAllServices({ commit, state }) {
      commit('changeProgress', true)
      let dateTemp = this.state.dateSelected
      if (state.realTime) {
        dateTemp = new Date()
      }
      state.socket.emit('getServicesNames', {
        dateSelected: dateTemp,
      })
    },
    socketData({ commit, dispatch, state }) {
      dispatch('getAllServices')
      state.socket.on('getLogs', (logs) => {
        dispatch('resetLogs')
        if (logs.length !== 0) {
          if (logs[0].level === state.selectedLogType.toLowerCase()) {
            if (logs.length <= 200) {
              commit('newLogs', logs)
              commit('tempLogsHolder', [])
            } else {
              commit('newLogs', logs.slice(logs.length - 200))
              commit('tempLogsHolder', logs.slice(0, logs.length - 200))
            }
          }
        }
        dispatch('getAllServices')
        commit('changeProgress', false)
      })

      state.socket.on('serviceNames', (names) => {
        commit('serviceNames', names)
        commit('changeProgress', false)
      })
    },
    resetLogs({ dispatch }) {
      dispatch('updateStateDirect', {
        fieldName: 'logs',
        fieldValue: [],
      })
      dispatch('updateStateDirect', {
        fieldName: 'tempLogsHolder',
        fieldValue: [],
      })
    },
    getResMsg({ dispatch, state }) {
      state.socket.on('getResMsg', (resMsg) => {
        dispatch('updateStateDirect', {
          fieldName: 'resMsg',
          fieldValue: resMsg,
        })
      })
    },
  },
  modules: {},
})
