<template>
  <div
    class="md-layout md-alignment-center-center"
    style="padding:10px;margin-top:20px"
  >
    <!-- <BackToMainMenu /> -->
    <!-- <div
      class="md-layout-item md-size-100 md-layout md-alignment-center-center "
    >
      <select
        class="md-layout-item md-size-50 md-xsmall-size-90 rounded bg-grey"
        v-model="selectedService"
        style="height:40px"
      >
        <option value="null" disabled>--- Select a channel ---</option>
        <option
          v-for="channel of channels"
          :value="channel.id"
          :key="channel.id"
        >
          {{ channel.name }}
        </option>
      </select>
    </div> -->
    <div v-if="resMsg" class="show-resMsg">{{ resMsg }}</div>
    <div
      class="md-layout-item md-size-100 md-layout md-alignment-center-center"
    >
      <div
        class="md-layout-item md-size-60 md-xsmall-size-90 md-layout md-alignment-center-center"
        style="padding:20px 0"
      >
        <br />
        <label>Pick a start date:</label>
      </div>
      <div
        class="md-layout-item md-size-50 md-xsmall-size-90 md-layout md-alignment-center-space-around"
      >
        <md-datepicker
          class="md-layout-item md-size-45 md-xsmall-size-90"
          name="datepicker"
          v-model="startDate"
          md-immediately
          :md-disabled-dates="disabledDates"
        />
        <input
          class="md-layout-item md-size-45 md-xsmall-size-90 rounded"
          style="border: 1px solid #ccc;padding:8px 10px"
          type="time"
          v-model="startTime"
        />
      </div>
      <div
        class="md-layout-item md-size-100 md-layout md-alignment-center-center"
      >
        <label
          class="md-layout-item md-size-60 md-xsmall-size-90 md-layout md-alignment-center-center"
          style="padding:20px 0"
          >Pick an end date:</label
        >
        <div
          class="md-layout-item md-size-50 md-xsmall-size-90 md-layout md-alignment-center-space-around"
        >
          <md-datepicker
            class="md-layout-item md-size-45 md-xsmall-size-90"
            name="datepicker"
            v-model="endDate"
            md-immediately
            :md-disabled-dates="disabledDates"
          />
          <input
            class="md-layout-item md-size-45 md-xsmall-size-90 rounded"
            style="border: 1px solid #ccc;padding:8px 10px"
            type="time"
            v-model="endTime"
          />
        </div>
      </div>
      <div
        class="md-layout-item md-size-100 md-layout md-alignment-center-center"
        style="margin-top:10px"
      >
        <md-button class="md-raised md-accent" @click="deleteMsg"
          >Delete Slack Messages</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import httpRequest from '../services/httpRequest'
  import { loggerBackend } from '../configs/apiConfig'
  export default {
    name: 'DeleteSlackMSG',
    data: () => {
      return {
        channels: [
          // { name: 'testing', id: 'C6K4CKSQG' },
          // { name: 'general', id: 'C9RFBS9GA' },
          // { name: 'passwords', id: 'CHASW2MMF' },
          // { name: 'clickup', id: 'CMA78GUAC' },
          // { name: 'logs-schedule', id: 'CN7BAU8RH' },
          // { name: 'logs-test', id: 'CQ7TZKLEN' },
          // { name: 'logs', id: '>' },
        ],
        selectedService: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        disabledDates: (date) => {
          return date.getTime() > new Date().getTime()
        },
      }
    },
    computed: {
      resMsg() {
        return this.$store.state.resMsg
      },
    },
    beforeDestroy() {
      this.$store.dispatch('updateStateDirect', {
        fieldName: 'resMsg',
        fieldValue: '',
      })
    },
    methods: {
      async deleteMsg() {
        const res = await httpRequest(`${loggerBackend}deleteSlackMsg`, {
          method: 'post',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            startDate: this.startDate,
            endDate: this.endDate,
            startTime: this.startTime,
            endTime: this.endTime,
          }),
        })
        this.$store.dispatch('getResMsg')
      },
    },
  }
</script>

<style>
  .show-resMsg {
    background-color: green;
    padding: 6px 12px;
    font-size: 16px;
  }
</style>
